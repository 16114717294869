import { mobileMax, mobileXLargeMax, mobileXXLargeMax } from '@propertypal/shared/src/constants/mediaQueries';
import styled from 'styled-components';

export const VideoBox = styled.video<{ fullscreen?: boolean }>`
  position: absolute;
  width: 100%;
  height: 100%;
  ${({ fullscreen }) => (!fullscreen ? `object-fit: cover;` : '')}
  min-height: 100%;
  max-height: 100%;
  z-index: 1;

  @media (${mobileXXLargeMax}) {
    display: none;
  }
`;

export const MobileVideoBox = styled.video<{ fullscreen?: boolean }>`
  display: none;
  position: absolute;
  width: 100%;
  height: 100%;
  ${({ fullscreen }) => (!fullscreen ? `object-fit: cover;` : '')}
  min-height: 100%;
  max-height: 100%;
  z-index: 1;

  @media (${mobileXXLargeMax}) {
    display: block;
  }
`;

export const FullscreenContainer = styled.div`
  background-color: ${(props) => props.theme.black};
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

export const FullscreenBtn = styled.div`
  position: absolute;
  bottom: 55px;
  right: 10px;
  height: 20px;
  background-color: ${({ theme }) => theme.textDark};
  color: ${({ theme }) => theme.white};
  z-index: 3;
  padding: 3px 6.5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 11px;
  font-weight: 600;
  cursor: pointer;
  border-radius: 2px;
  transition: background-color 300ms linear;

  &:hover {
    background-color: ${({ theme }) => theme.black};
  }

  @media screen and (${mobileXXLargeMax}) {
    right: 50%;
    transform: translateX(50%);
  }
`;

export const BackToHome = styled.div`
  position: absolute;
  top: 15px;
  left: 12.5px;
  height: 22px;
  color: ${({ theme }) => theme.white};
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  cursor: pointer;
  transition: text-shadow 300ms linear;
  text-shadow: 0 0 0 rgba(0, 0, 0, 0.5);

  & svg {
    transition: filter 300ms linear;
    filter: drop-shadow(0 0 0px rgba(0, 0, 0, 1));
  }

  &:hover {
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);

    & svg {
      filter: drop-shadow(0 0 3px rgba(0, 0, 0, 1));
    }
  }
`;

export const Title = styled.h2`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
  color: ${({ theme }) => theme.white};
  font-size: 24px;
  margin: 0 15px;
  z-index: 1;
  text-shadow: rgb(0 0 0 / 70%) 2px 2px 6px;

  /* use relative font size until mobile */
  @media screen and (min-width: 768px) {
    font-size: 3.1vw;
    width: calc(100% - 26vw + 15px);
  }

  @media screen and (max-width: 650px) {
    top: calc(50% + 40px);
  }

  @media screen and (${mobileXLargeMax}) {
    font-size: 20px;
    margin: 0 10px;
    overflow: hidden;
    max-height: 110px;
  }

  @media screen and (${mobileMax}) {
    font-size: 18px;
    max-height: 100px;
  }
`;

export const IconWrapper = styled.div`
  height: 24px;
  margin-right: 10px;
  align-self: center;
`;

export const ShowHomeMob = styled.div`
  display: none;
  position: absolute;
  bottom: 100px;
  right: calc(50% - 10px);
  transform: translateX(50%);
  z-index: 1;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.75);
  width: calc(100% - 20px);
  text-align: center;
  margin: 0 10px;

  @media (max-width: 834px) {
    display: block;
  }
`;

export const TakeoverLogo = styled.img`
  z-index: 2;
  height: calc(100% - 250px);

  @media (max-width: 780px) {
    max-height: calc(100% - 260px);
    height: auto;
    margin-top: 40px;
    max-width: 100%;
  }
`;
