import React, { FunctionComponent } from 'react';
import {
  Container,
  Content,
  Footer,
  HeroImage,
  Logo,
  Row1,
  Row2,
  Row3,
  Row4,
  Row5,
  Row6,
  Row7,
  TextContent,
} from './PropertyPlaceholder.style';

interface Props {
  useWideDisplay?: boolean;
  hideFooter?: boolean;
}

const PropertyPlaceholder: FunctionComponent<Props> = (props) => {
  return (
    <Container data-testid="property-placeholder">
      <Content $useWideDisplay={props.useWideDisplay}>
        <HeroImage $useWideDisplay={props.useWideDisplay} />

        <TextContent>
          <Row1 />
          <Row2 />
          <Row3 />

          <Row4 />
          <Row5 />

          {!props.hideFooter && (
            <Footer>
              <Logo />

              <div>
                <Row6 />
                <Row7 />
              </div>
            </Footer>
          )}
        </TextContent>
      </Content>
    </Container>
  );
};

export default PropertyPlaceholder;
