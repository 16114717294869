import { mobileXXLargeMax } from '@propertypal/shared/src/constants/mediaQueries';
import styled from 'styled-components';

export const Container = styled.li`
  list-style: none;
  display: block;
  background-color: ${(props) => props.theme.background};
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
`;

export const HeroImage = styled.div<{ $useWideDisplay?: boolean }>`
  background-color: ${(props) => props.theme.backgroundLightest};
  aspect-ratio: 1.36 / 1;

  ${({ $useWideDisplay }) =>
    $useWideDisplay &&
    `
    width: 320px;
  `}

  @media (${mobileXXLargeMax}) {
    width: auto;
  }
`;

export const Content = styled.div<{ $useWideDisplay?: boolean }>`
  display: flex;
  flex-direction: ${({ $useWideDisplay }) => ($useWideDisplay ? 'row' : 'column')};

  @media (${mobileXXLargeMax}) {
    flex-direction: column;
  }
`;

export const TextContent = styled.div`
  margin: 12px 18px 12px;
  flex: 1;
`;

export const Row1 = styled.div`
  background-color: ${(props) => props.theme.backgroundLightest};
  width: 100%;
  max-width: 292px;
  height: 22px;
  margin-bottom: 3px;
`;

export const Row2 = styled.div`
  background-color: ${(props) => props.theme.backgroundLightest};
  width: 130px;
  height: 18px;
  margin-bottom: 9px;
`;

export const Row3 = styled.div`
  background-color: ${(props) => props.theme.backgroundLightest};
  width: 157px;
  height: 17px;
  margin-bottom: 30px;
`;

export const Row4 = styled.div`
  background-color: ${(props) => props.theme.backgroundLightest};
  width: 150px;
  height: 17px;
  margin-bottom: 12px;
`;

export const Row5 = styled.div`
  background-color: ${(props) => props.theme.backgroundLightest};
  width: 70px;
  height: 22px;
`;

export const Logo = styled.div`
  background-color: ${(props) => props.theme.backgroundLightest};
  width: 92px;
  height: 46px;
  margin-right: 15px;
`;

export const Footer = styled.div`
  display: flex;
  margin: 15px 0 0;
`;

export const Row6 = styled.div`
  background-color: ${(props) => props.theme.backgroundLightest};
  width: 74px;
  height: 17px;
  margin-bottom: 3px;
`;

export const Row7 = styled.div`
  background-color: ${(props) => props.theme.backgroundLightest};
  width: 136px;
  height: 22px;
`;
