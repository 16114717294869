import { faBell } from '@fortawesome/pro-light-svg-icons/faBell';
import { faHeart } from '@fortawesome/pro-light-svg-icons/faHeart';
import { faUsers } from '@fortawesome/pro-light-svg-icons/faUsers';

const featuresTrioData = [
  {
    id: 1,
    icon: faHeart,
    title: 'Save your favourites',
    introduction: 'Keep track of your favourite homes and stay up to date on price and status changes.',
    linkText: 'Sign up',
    ariaLabel:
      // eslint-disable-next-line max-len
      'Sign up for property pal and keep track of your favourites homes as well as staying up to date on any price or status changes',
    link: '/register',
  },
  {
    id: 2,
    icon: faBell,
    title: 'Never miss a listing',
    introduction: 'Get homes right into your inbox by turning your searches into alerts.',
    linkText: 'Setup email alerts',
    ariaLabel: 'Setup email alerts, and receive the latest properties directly in your email',
    link: '/my/alerts',
  },
  {
    id: 3,
    icon: faUsers,
    title: 'Pals & Sharing',
    introduction: 'Add as many pals as you like and start sharing your favourites, searches and notes.',
    linkText: 'Add pals',
    ariaLabel: 'Add a pal to your account to start sharing your favourite properties, searches and your notes',
    link: '/my/pals',
  },
];

export default featuresTrioData;
