import GuideIcon1 from '@propertypal/shared/src/resources/icons/content-hub/guide-icon-1.svg';
import GuideIcon2 from '@propertypal/shared/src/resources/icons/content-hub/guide-icon-2.svg';
import GuideIcon3 from '@propertypal/shared/src/resources/icons/content-hub/guide-icon-3.svg';
import GuideIcon4 from '@propertypal/shared/src/resources/icons/content-hub/guide-icon-4.svg';
import React, { FunctionComponent } from 'react';
import { useTheme } from 'styled-components';
import Chevron from '../icons/Chevron';
import { Container, Box, BoxText, ExploreContent, Content } from './GuidesBar.style';

const GuidesBar: FunctionComponent = () => {
  const theme = useTheme();

  return (
    <Container>
      <Content>
        <h2>Explore our Property Guides</h2>

        <ExploreContent>
          <Box href="/content-hub" prefetch={false}>
            <GuideIcon1 />

            <div>
              <BoxText>A complete guide to buying a home</BoxText>

              <BoxText color={theme.primary}>
                Read more <Chevron size={12} />
              </BoxText>
            </div>
          </Box>

          <Box href="/content-hub" prefetch={false}>
            <GuideIcon2 />

            <div>
              <BoxText>How to decide the best place to live</BoxText>

              <BoxText color={theme.primary}>
                Read more <Chevron size={12} />
              </BoxText>
            </div>
          </Box>

          <Box href="/content-hub" prefetch={false}>
            <GuideIcon3 />

            <div>
              <BoxText>What is conveyancing and do I need it?</BoxText>

              <BoxText color={theme.primary}>
                Read more <Chevron size={12} />
              </BoxText>
            </div>
          </Box>

          <Box href="/content-hub" prefetch={false}>
            <GuideIcon4 />

            <div>
              <BoxText>How to manage your property chain</BoxText>

              <BoxText color={theme.primary}>
                Read more <Chevron size={12} />
              </BoxText>
            </div>
          </Box>
        </ExploreContent>
      </Content>
    </Container>
  );
};

export default GuidesBar;
