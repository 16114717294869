import { CH_HOME_FEATURE, NEWS_FEATURE } from '@propertypal/shared/src/constants/content-hub';
import useRecentSearches from '@propertypal/shared/src/hooks/useRecentSearches';
import { propertySearch } from '@propertypal/shared/src/reducers/properties';
import { initialState, setSearch } from '@propertypal/shared/src/reducers/search';
import { RootState, useAppDispatch } from '@propertypal/shared/src/reducers/store';
import { trackGaEvent, trackGaLink } from '@propertypal/shared/src/services/analytics';
import axios, { API_URL } from '@propertypal/shared/src/services/axios';
import strapi, { STRAPI_URL } from '@propertypal/shared/src/services/axios.strapi';
import { HomepageTakeover } from '@propertypal/shared/src/types/homepageTakeover';
import { Property } from '@propertypal/shared/src/types/property';
import { StrapiResponse } from '@propertypal/shared/src/types/strapi/generic';
import { ContentHubArticle, ConvertedContentHubArticle } from '@propertypal/shared/src/types/strapi/overrides';
import { SuperFeatureProperty } from '@propertypal/shared/src/types/superFeature';
import { findRichText } from '@propertypal/shared/src/utils/content-hub';
import hasFeature from '@propertypal/shared/src/utils/featureFlag';
import { Filters } from '@propertypal/shared/src/utils/search/getNlpFilters';
import ImageSlideshow from '@propertypal/web-ui/src/animation/ImageSlideshow';
import FeaturesTrio from '@propertypal/web-ui/src/home/FeaturesTrio';
import GuidesBar from '@propertypal/web-ui/src/home/GuidesBar';
import LatestNews from '@propertypal/web-ui/src/home/LatestNews';
import SimilarProperties from '@propertypal/web-ui/src/property/SimilarProperties';
import Takeover from '@propertypal/web-ui/src/takeover/Takeover';
import pick from 'lodash/pick';
import Head from 'next/head';
import router from 'next/router';
import { GetServerSideProps } from 'next/types';
import React, { FunctionComponent } from 'react';
import { useDispatch, useStore } from 'react-redux';
import { useTheme } from 'styled-components';
import generateMetaTags from '../components/layout/MetaTags';
import HomeSearchBar from '../components/search/HomeSearchBar';
import featuresTrioData from '../constants/homepage/featuresTrioData';
import cachedRequest from '../services/cachedRequest';
import { getCfIpCountry, requireAuthToken } from '../utils/auth';

interface Props {
  superfeatures: SuperFeatureProperty[];
  recent: Property[];
  popular: Property[];
  takeover: HomepageTakeover | null;
  articles: ConvertedContentHubArticle[] | null;
  newsAndArticles: boolean;
}

const metaData = {
  title: 'PropertyPal - Find Property For Sale And Rent In Ireland And Northern Ireland',
  description:
    'Browse Through More Than 60,000 Properties For Sale And Rent In Ireland And Northern Ireland. PropertyPal Is Your Best Friend In Property.',
  url: '',
};

const simplifyProperty = (property: Property) => {
  return pick(property, [
    'account',
    'agentLogos',
    'images',
    'path',
    'id',
    'displayAddress',
    'price',
    'epc',
    'ber',
    'logos',
    'addressLine1',
    'town',
    'alternativeCurrencyPrice',
    'listingTime',
    'agents',
    'numBedrooms',
    'numBathrooms',
    'numReceptionRooms',
    'viewsInLast7Days',
  ]);
};

export const getServerSideProps: GetServerSideProps = requireAuthToken(async (ctx, store) => {
  const result = await axios(
    {
      method: 'GET',
      url: `${API_URL}/homepage`,
    },
    store.dispatch,
    ctx,
  );

  const news = NEWS_FEATURE
    ? await strapi<StrapiResponse<ContentHubArticle[]>>({
        method: 'GET',
        url: `${STRAPI_URL}/articles`,
        params: {
          populate: 'poster,tabletPoster,mobilePoster,subCategories,content',
          'filters[subCategories][articleCategory][$in]': 'news-and-analysis',
          sort: 'publishedAt:desc',
          'pagination[pageSize]': 3,
        },
      })
    : null;

  const articles =
    news?.data?.data?.map((article) => ({
      ...article,
      attributes: {
        ...article.attributes,
        content: article.attributes.excerpt || findRichText(article.attributes.content),
      },
    })) || null;

  const superfeatures = result.data.superfeatures.sort(() => Math.random() - 0.5);

  const popular = result.data.popular.sort(() => Math.random() - 0.5).map(simplifyProperty);

  const siteMapLinks =
    getCfIpCountry(ctx) === 'IE'
      ? await cachedRequest('/site-map/footer/irl', ctx, 6)
      : await cachedRequest('/site-map/footer/nir', ctx, 6);

  let takeover: Props['takeover'] = null;

  if (result.data.takeover) {
    // simplify listing property from takeover in result
    takeover = {
      ...result.data.takeover,
      // Any changes here should be reflected in the HomepageTakeover["listing"] type
      listing: pick(result.data.takeover.listing, [
        'listingLogos',
        'path',
        'price',
        'showHomeOpeningTime',
        'images',
        'displayAddress',
        'displayAddressLine1',
      ]),
    };
  }

  return {
    props: {
      superfeatures,
      recent: result.data.recent.map(simplifyProperty),
      popular,
      siteMapLinks,
      takeover,
      articles,
      newsAndArticles: hasFeature(result.headers, 'sc-29605-news'),
    },
  };
});

const Home: FunctionComponent<Props> = (props) => {
  const images = props.superfeatures.map((row) => row.url || row.image.url);
  const highResImages = props.superfeatures.map((row) => row.url || row.image?.urls['1600x1067:FILL_CROP']);
  const dispatch = useDispatch();
  const asyncDispatch = useAppDispatch();
  const store = useStore<RootState>();
  const { recentSearches, addRecentSearch, removeRecentSearch } = useRecentSearches();
  const theme = useTheme();

  const handleSubmit = async (baseUrl: string, searchText: string, filters: Filters) => {
    dispatch(
      setSearch({
        text: searchText,
        filters: { ...initialState.filters, ...filters },
      }),
    );

    await asyncDispatch(propertySearch('hd'));

    const { properties } = store.getState();

    if (properties.data) {
      addRecentSearch({
        description: properties.data.description,
        text: searchText,
        filters,
      });

      trackGaEvent('nav_click', {
        page: 'HP',
        location: 'Search Box',
        button: filters.category === 'newhomes' ? 'New Homes' : filters.saleType === 'rent' ? 'For Rent' : 'For Sale',
      });

      await router.push(baseUrl + properties.data.url);
    }
  };

  return (
    <>
      <Head>{generateMetaTags(metaData)}</Head>

      {props.takeover && props.takeover.listing ? (
        <Takeover takeover={props.takeover}>
          <HomeSearchBar
            submitText="For Sale"
            showTravelPreview
            showSaleTypeOptions
            onSubmit={handleSubmit}
            recentSearches={recentSearches}
            removeRecentSearch={removeRecentSearch}
          />
        </Takeover>
      ) : (
        <ImageSlideshow
          images={images || []}
          highResImages={highResImages}
          slideDuration={10000}
          superFeatureProperties={props.superfeatures}
          highResThreshold={1600}
        >
          <HomeSearchBar
            submitText="For Sale"
            showTravelPreview
            showSaleTypeOptions
            onSubmit={handleSubmit}
            recentSearches={recentSearches}
            removeRecentSearch={removeRecentSearch}
            // showInstantValuation={props.cfIpCountry !== 'IE'}
          />
        </ImageSlideshow>
      )}

      <SimilarProperties
        properties={props.popular}
        title="Most Viewed Properties"
        titleSize={36}
        subTitle="(Last 7 days)"
        showArrows
        showFooter
        onClick={trackGaLink('nav_click', 'Most Viewed', 'HP')}
      />

      <FeaturesTrio heading="House Hunting is Easier with PropertyPal" data={featuresTrioData} />

      {props.newsAndArticles && props?.articles && props?.articles?.length > 0 && (
        <>
          <LatestNews articles={props.articles} />

          {CH_HOME_FEATURE && <GuidesBar />}
        </>
      )}

      {props.recent && (
        <SimilarProperties
          properties={props.recent}
          titleSize={36}
          title="Recently Added Properties"
          showArrows
          showFooter
          onClick={trackGaLink('nav_click', 'Recently Added', 'HP')}
          {...(props.newsAndArticles && props?.articles && props?.articles?.length > 0 && !CH_HOME_FEATURE
            ? { arrowBg: theme.white, background: theme.backgroundLightest }
            : {})}
        />
      )}
    </>
  );
};

export default Home;
