import { laptopMax, tabletMax } from '@propertypal/shared/src/constants/mediaQueries';
import styled from 'styled-components';
import { MAX_WIDTH } from '../layout/PageWrapper.style';

export const Container = styled.div`
  padding: 65px 0;

  h2 {
    font-size: 36px;
  }

  @media (${laptopMax}) {
    padding: 30px 0;

    h2 {
      font-size: 26px;
    }
  }
`;

export const Content = styled.div`
  max-width: ${MAX_WIDTH}px;
  width: 100%;
  margin: 0 auto;
  padding: 0 30px;

  @media (${laptopMax}) {
    padding: 0 15px;
  }
`;

export const Rail = styled.div<{ columns?: number }>`
  display: grid;
  grid-template-columns: repeat(${({ columns }) => columns || 3}, 1fr);
  grid-column-gap: 30px;
  grid-row-gap: 15px;
  margin-top: 30px;

  @media (${laptopMax}) {
    grid-column-gap: 15px;
  }

  @media (${tabletMax}) {
    gap: 30px;
    grid-template-columns: repeat(1, 1fr);
  }
`;
