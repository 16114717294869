import { laptopMax, mobileXXLargeMax, tabletMax } from '@propertypal/shared/src/constants/mediaQueries';
import Link from 'next/link';
import styled from 'styled-components';
import { SubHeading, MidHeading } from '../typography';

const FeaturesTrioContainer = styled.section`
  background-color: ${({ theme }) => theme.backgroundLightest};
  padding: 65px 0;

  @media screen and (${tabletMax}) {
    padding: 30px 0;

    ${SubHeading} {
      font-size: 26px;
      margin-bottom: 30px;
    }

    ${MidHeading} {
      font-size: 20px;
    }
  }

  @media (${mobileXXLargeMax}) {
    ${SubHeading} {
      margin: 0px auto 30px auto;
      line-height: 33px;
    }
  }
`;

const FeaturesTrioListContainer = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  display: grid;

  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 2em;

  @media screen and (${laptopMax}) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (${tabletMax}) {
    grid-template-columns: 1fr;
  }
`;

const FeaturesElement = styled.li`
  display: flex;
`;

const FeatureContent = styled.div`
  margin-left: 20px;
`;

const FeatureIntroPara = styled.p`
  padding-bottom: 10px;
`;

const FeatureIcon = styled.div`
  min-width: 50px;

  & > svg {
    max-width: 100%;
  }
`;

const FeaturesLink = styled(Link)`
  color: ${(props) => props.theme.primary};
  font-weight: bold;
  display: inline-flex;
  & > svg {
    margin-left: 5px;
    font-size: 25px;
  }

  &:hover {
    color: ${(props) => props.theme.backgroundMid};
  }
`;

export {
  FeaturesTrioContainer,
  FeaturesTrioListContainer,
  FeaturesElement,
  FeatureContent,
  FeatureIntroPara,
  FeatureIcon,
  FeaturesLink,
};
