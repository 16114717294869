import { ConvertedContentHubArticle } from '@propertypal/shared/src/types/strapi/overrides';
import React, { FunctionComponent } from 'react';
import ContentHubArticleBox from '../content-hub/ContentHubArticleBox';
import Chevron from '../icons/Chevron';
import { TextLink } from '../typography';
import { Container, Content, Rail } from './LatestNews.style';

interface Props {
  articles: ConvertedContentHubArticle[];
}

const LatestNews: FunctionComponent<Props> = (props) => {
  return (
    <Container>
      <Content>
        <h2>Latest Property News and Analysis</h2>
        <p>
          <TextLink href="/news-and-analysis">
            View more news and analysis <Chevron size={10} />
          </TextLink>
        </p>

        <Rail>
          {props.articles.map((article) => (
            <ContentHubArticleBox key={article.id} article={article} />
          ))}
        </Rail>
      </Content>
    </Container>
  );
};

export default LatestNews;
