/* eslint-disable no-undef */
import 'intersection-observer';
import { RefObject, useEffect, useRef, useState } from 'react';

const useInView = (el: RefObject<any>, options: IntersectionObserverInit, callback?: () => void) => {
  const [inView, setInView] = useState(false);
  const observer = useRef<IntersectionObserver | null>(null);

  const detachObserver = () => {
    observer.current?.disconnect();
  };

  const onInRange: IntersectionObserverCallback = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        setInView(true);
        if (callback) callback();
        detachObserver();
      }
    });
  };

  useEffect(() => {
    if (el.current) {
      observer.current = new IntersectionObserver(onInRange, options);
      observer.current?.observe(el.current);
    }

    return detachObserver;
  }, []);

  return inView;
};

export default useInView;
