import { ConvertedContentHubArticle } from '@propertypal/shared/src/types/strapi/overrides';
import { getArticleImage, getArticleUrl } from '@propertypal/shared/src/utils/content-hub';
import React, { FunctionComponent } from 'react';
import Chevron from '../icons/Chevron';
import { Box } from '../layout';
import { NewsBox, NewsContent, NewsImage, ReadMoreBtn } from './ContentHubArticleBox.style';

interface Props {
  article: ConvertedContentHubArticle;
  readMoreBtn?: boolean;
}

const ContentHubArticleBox: FunctionComponent<Props> = ({ article, readMoreBtn }) => {
  const articleUrl = getArticleUrl(article);

  return (
    <NewsBox href={articleUrl} data-testid="content-hub-article">
      {/* {article.attributes.tag?.data?.attributes && (
        <ArticleTag
          data-testid="article-box-tag"
          colour={article.attributes.tag.data?.attributes.textColour}
          bg={article.attributes.tag.data?.attributes.backgroundColour}
          mb={10}
        >
          {article.attributes.tag.data?.attributes.tag}
        </ArticleTag>
      )} */}
      <NewsImage image={getArticleImage(article)} />

      <NewsContent readMoreBtn={readMoreBtn}>
        <Box gap={15} display="flex" flexDirection="column">
          <h3>{article.attributes.title}</h3>

          <p>{article.attributes.content}</p>
        </Box>

        {readMoreBtn ? (
          <ReadMoreBtn data-testid="read-more-btn">
            <span>Read more</span>
          </ReadMoreBtn>
        ) : (
          <p>
            Read more <Chevron size={11} />
          </p>
        )}
      </NewsContent>
    </NewsBox>
  );
};

export default ContentHubArticleBox;
