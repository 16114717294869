import { faAngleRight } from '@fortawesome/pro-light-svg-icons/faAngleRight';
import React from 'react';
import { useTheme } from 'styled-components';
import FontAwesomeIcon from '../icons/FontAwesomeIcon';
import PageWrapper from '../layout/PageWrapper';
import { SubHeading, MidHeading } from '../typography';
import {
  FeaturesTrioContainer,
  FeaturesTrioListContainer,
  FeaturesElement,
  FeatureContent,
  FeatureIntroPara,
  FeatureIcon,
  FeaturesLink,
} from './FeaturesTrio.style';

interface TrioLink {
  id: number;
  icon: any;
  title: string;
  introduction: string;
  link: string;
  linkText: string;
  ariaLabel?: string;
}

interface FeaturesTrioProps {
  data: TrioLink[];
  heading: string;
}

const FeaturesTrio = (props: FeaturesTrioProps) => {
  const theme = useTheme();

  return (
    <FeaturesTrioContainer>
      <PageWrapper>
        <SubHeading mb={60} fontSize={36}>
          {props.heading}
        </SubHeading>

        <FeaturesTrioListContainer>
          {props.data.map((trioLink) => {
            return (
              <FeaturesElement key={trioLink.id}>
                <FeatureIcon>
                  <FontAwesomeIcon icon={trioLink.icon} size={48} color={theme.textDark} />
                </FeatureIcon>
                <FeatureContent>
                  <MidHeading mb={10} fontSize={22}>
                    {trioLink.title}
                  </MidHeading>
                  <FeatureIntroPara>{trioLink.introduction}</FeatureIntroPara>

                  <FeaturesLink aria-label={trioLink.ariaLabel} href={trioLink.link}>
                    {trioLink.linkText} <FontAwesomeIcon icon={faAngleRight} />
                  </FeaturesLink>
                </FeatureContent>
              </FeaturesElement>
            );
          })}
        </FeaturesTrioListContainer>
      </PageWrapper>
    </FeaturesTrioContainer>
  );
};

export default FeaturesTrio;
