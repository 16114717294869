import { laptopMax, mobileXXLargeMax } from '@propertypal/shared/src/constants/mediaQueries';
import Link from 'next/link';
import styled from 'styled-components';
import { MAX_WIDTH } from '../layout/PageWrapper.style';

export const Container = styled.div`
  background: ${(props) => props.theme.backgroundLightest};
  padding: 65px 0;

  h2 {
    font-size: 36px;
  }

  @media (${laptopMax}) {
    padding: 30px 0;

    h2 {
      font-size: 26px;
    }
  }
`;

export const Content = styled.div`
  max-width: ${MAX_WIDTH}px;
  width: 100%;
  margin: 0 auto;
  padding: 0 30px;

  @media (${laptopMax}) {
    padding: 0 15px;
  }
`;

export const ExploreContent = styled.div`
  display: flex;
  margin: 30px 0 20px;
  justify-content: space-between;
  flex-wrap: wrap;

  @media (${laptopMax}) {
    margin: 30px 0 0;
  }

  @media (${mobileXXLargeMax}) {
    flex-direction: column;
  }
`;

export const Box = styled(Link)`
  display: flex;
  background-color: ${(props) => props.theme.background};
  border-radius: 8px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
  padding: 10px 15px 15px 15px;
  width: 23%;

  & > svg {
    min-width: 40px;
    width: 40px;
    height: 40px;
    margin-right: 15px;
  }

  @media (${laptopMax}) {
    width: 48%;
    margin-bottom: 15px;
  }

  @media (${mobileXXLargeMax}) {
    width: 100%;
  }
`;

export const BoxText = styled.p<{ color?: string }>`
  font-size: 15px;
  font-weight: 600;
  color: ${(props) => props.color || props.theme.textDark};

  &:first-child {
    margin-bottom: 5px;
  }
`;
